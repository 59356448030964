import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./utils/localization/config";
import Root from "./routes";
import { store, persistedStore } from "./store";
import { ToastContainer } from "react-toastify";
import fe_config from "constants/Configs";
import { PersistGate } from "redux-persist/integration/react";
import "assets/css/main.css";

/* eslint eqeqeq: "off", curly: "error" */

fe_config.MODE === "PROD" && (console.warn = console.error = () => {});
fe_config.MODE === "DEV" && (console.warn = () => {});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <>
          <Root i18n={i18n} />
          <ToastContainer />
        </>
      </PersistGate>
    </Provider>
  </I18nextProvider>
);