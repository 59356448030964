import { ReducersTypes } from "constants/ReducersTypes";
import { instanceWithRetry } from "actions/axiosInstance";
import { handleAPIError } from "handlers/setters";
import omit from "lodash/omit";
import uniqBy from "lodash/uniqBy";
import { trimText } from "utils/strings";
import { setReducerData } from "utils";
import { enableForArDemo } from "handlers/features";
import { store } from "store";

const taskPriority = {
  CRITICAL: 1,
  HIGH: 2,
  MEDIUM: 3,
  LOW: 4
};

const sortByPriority = (a, b) => taskPriority[a.task_priority] - taskPriority[b.task_priority];

export const getTaskList = (params) => (dispatch) => {
  dispatch(setReducerData(ReducersTypes.FETCH_TASK_LIST, { isFetching: true }));
  const omitParams = ["totalPages", "showFilter", "date_label", "columnsCount", "0", "1", "2", "3"];
  const paramString = params
    ? Object.keys(params)
        .filter((d) => params[d])
        .filter(d => !omitParams.includes(d))
        .map((key) => `${key}=${params[key]}`)
        .join("&")
    : "";

  const URL = `artask/list?${paramString}`;
  return instanceWithRetry
    .get(URL)
    .then((response) => {
      if (response && response.data) {
        const { result, next_num, prev_num, pages } = response.data;
        const todoList = result.filter(d => d.latest_status === "TODO").sort(sortByPriority);
        const inprogressList = result.filter(d => d.latest_status === "IN_PROGRESS").sort(sortByPriority);
        const doneList = result.filter(d => d.latest_status === "DONE").sort(sortByPriority);
        const sortingForDemo = [...todoList, ...inprogressList, ...doneList];
        dispatch(
          setReducerData(ReducersTypes.FETCH_TASK_LIST, {
            taskList: enableForArDemo() ? sortingForDemo : result,
            isFetching: false,
            filters: {
              ...params,
              nextPage: next_num,
              prevPage: prev_num,
              totalPages: pages
            },
          })
        );
        return result;
      }
    })
    .catch((e) => {
      dispatch(
        setReducerData(ReducersTypes.FETCH_TASK_LIST, {
          isFetching: false,
          filters: params,
        })
      );
      handleAPIError(e);
      return;
    });
};
export const createOrUpdateTask = (params) => {
  let base_url = "artask?mutate=1"
  if (params.id) {
    base_url = `artask?id=${params.id}&mutate=1`
  }
  if (!params.id) {
    if (Array.isArray(params.ar_invoice_header_id) && params.ar_invoice_header_id.length) {
      const promises = params.assigned_to.map((user) =>
      params.ar_invoice_header_id.map(invoice => instanceWithRetry.post(base_url, {
        ...omit(params, ["id", "ar_invoice_header_id"]),
        assigned_to: user,
        ar_invoice_header_id: invoice,
      }))
      ).flat();
      return Promise.allSettled(promises)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          handleAPIError(e);
          return;
        });
    }
    if (params.assigned_to?.length > 0) {
      const promises = params.assigned_to.map((user) =>
        instanceWithRetry.post(base_url, {
          ...omit(params, ["id"]),
          assigned_to: user,
        })
      );
      return Promise.allSettled(promises)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          handleAPIError(e);
          return;
        });
    } else {
      return instanceWithRetry
        .post(base_url, {
          ...omit(params, ["id"])
        })
        .then((response) => {
          return response;
        })
        .catch((e) => {
          handleAPIError(e);
          return;
        });
    }
  } else {
    if (params.assigned_to?.length > 0) {
      const promises = params.assigned_to.map((user) =>
        instanceWithRetry.put(base_url, {
          ...omit(params, ["id"]),
          assigned_to: user,
        })
      );
      return Promise.allSettled(promises)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          handleAPIError(e);
          return;
        });
    } else {
      return instanceWithRetry
        .put(base_url, {
          ...omit(params, ["id"]),
          assigned_to: null,
        })
        .then((response) => {
          return response;
        })
        .catch((e) => {
          handleAPIError(e);
          return;
        });
    }
  }
};

export const bulkUpdateTaskStatus = (ids,status = "DONE") => {
  if (!ids.length) return;
  const allpromises = ids.map((id) =>
    instanceWithRetry.put(`artask?id=${id}&mutate=1`, { task_status: status })
  );
  return Promise.allSettled(allpromises)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      handleAPIError(e);
      return;
    });
};

export const editTasks = (params) => (dispatch) => {
  dispatch(
    setReducerData(ReducersTypes.EDIT_TASK_LIST, {
      selectedRows: params.selectedRows,
      isheaderchecked: params.isheaderchecked,
      isrowselected: params.selectedRows && !!params.selectedRows.length,
      selectedTask: params.selectedTask,
    })
  );
};

export const deleteTask = (ids) => {
  const requestObj = { data: { ids } };
  return instanceWithRetry
    .delete("artask?mutate=1", requestObj)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      handleAPIError(e);
      return;
    });
};

export const searchCustomer = (text, callback, assigned_to = []) => {
  const user_role_id = assigned_to.filter(user => user.role === "arrep").map(user => user.user_role_id);
  const user_ids = assigned_to.map(user => user.user_role_id);
  if (text) {
    instanceWithRetry
      .get(`customer/rel/search?name=${trimText(text)}${user_role_id.length ? `&ar_rep_user_role_id=${user_role_id}` : ""}${user_ids.length ? `&user_role_id=${user_ids}` : ""}&per_page=100`)
      .then((res) => {
        const options = uniqBy(res.data.result, "id").map((d) => ({
          value: d.id,
          label: d.display_string,
          customer_id: d.customer_id
        }));
        callback(options);
      })
      .catch((e) => handleAPIError(e));
  } else {
    callback([]);
  }
};

export const searchInvoices = (text, callback) => {
  const url = `arinvoice/search?invoice=${trimText(text)}`;
  if (text) {
    instanceWithRetry
      .get(url)
      .then((res) => {
        const options = uniqBy(res.data.result, "id").map((d) => ({
          value: d.id,
          label: d.invoice_number,
        }));
        callback(options);
      })
      .catch((e) => handleAPIError(e));
  } else {
    callback([]);
  }
};

export const getUsersList = () => (dispatch) => {
  dispatch(setReducerData(ReducersTypes.USER_LIST_FETCH, { isFetching: true }));
  return instanceWithRetry
    .get(`client/user?skip_customers=1&page=1&per_page=1000`)
    .then((res) => {
      const usersList = res.data.result.map((d) => ({
        role: d.role,
        email: d.email,
        username: d.username,
        user_id: d.id,
        user_role_id: d.user_role_id,
      }));
      dispatch(setReducerData(ReducersTypes.USER_LIST_FETCH, { isFetching: false, list: usersList }));
      return;
    })
    .catch((e) => {
      dispatch(
        setReducerData(ReducersTypes.USER_LIST_FETCH, {
          isFetching: false
        })
      );
      handleAPIError(e);
      return;
    });
};

export const getARTaskUserList = (customer_rel_id = "") => {
  instanceWithRetry
    .get(`task/user${customer_rel_id ? `?client_customer_rel_id=${customer_rel_id}`: ""}`)
    .then((res) => {
      store.dispatch({
        type: ReducersTypes.SET_DASHBOARD_DATA,
        payload: {
          arUserList: res && res.data ? res.data.result : []
        },
      });
    });
}

export const searchCustomerFilter = (text) =>
  instanceWithRetry
    .get(`customer/rel/search?name=${trimText(text)}&per_page=100`)
    .catch((e) => handleAPIError(e));

