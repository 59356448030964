export const ReducersTypes = {
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  AUTH: "AUTH",
  DASHBOARD: "DASHBOARD",
  PAYABLES: "PAYABLES",
  NON_INVOICED: "NON_INVOICED",
  NAVIGATION: "NAVIGATION",
  NAVIGATION_RESET: "NAVIGATION_RESET",
  PAYMENT_METHODS: "PAYMENT_METHODS",
  RECEIVABLES: "RECEIVABLES",
  SETTINGS: "SETTINGS",
  SET_USER_STATE: "SET_USER_STATE",
  SET_USER_FILTER: "SET_USER_FILTER",
  SET_ADD_USER_DATA: "SET_ADD_USER_DATA",
  CONFIG_SETTINGS: "CONFIG_SETTINGS",
  UPDATE_DISCOUNT_STATE: "UPDATE_DISCOUNT_STATE",
  UPDATE_RECEIVABLES_DATA: "UPDATE_RECEIVABLES_DATA",
  UPDATE_RECEIVABLES_STATE: "UPDATE_RECEIVABLES_STATE",
  CONFIRMATION_BOX: "CONFIRMATION_BOX",
  FETCH_ACTIVITY_LIST: "FETCH_ACTIVITY_LIST",
  FETCH_USERS_LIST: "FETCH_USERS_LIST",
  APPLY_USER_FILTER: "APPLY_USER_FILTER",
  CONFIRMATION_BOX_RESET: "CONFIRMATION_BOX_RESET",
  EMAIL_DATA: "EMAIL_DATA",
  EMAIL_RESET: "EMAIL_RESET",
  DYNAMIC_FORM: "DYNAMIC_FORM",
  ADD_DYNAMIC_FORM: "ADD_DYNAMIC_FORM",
  PREVIEW_DOCUMENT: "PREVIEW_DOCUMENT",
  RESET_PREVIEW_DOCUMENT: "RESET_PREVIEW_DOCUMENT",
  REPORTS: "REPORTS",
  REPORTS_CASH_IN_TOTAL: "REPORTS_CASH_IN_TOTAL",
  REPORTS_CASH_OUT_TOTAL: "REPORTS_CASH_OUT_TOTAL",
  REPORTS_ENDING_CASH_BALANCE: "REPORTS_ENDING_CASH_BALANCE",
  SET_REPORTS_CORPORATE_ENTITIES: "SET_REPORTS_CORPORATE_ENTITIES",
  RECALCULATE_REPORTS: "RECALCULATE_REPORTS",
  RESET_REPORTS: "RESET_REPORTS",
  LABEL_SETTINGS: "LABEL_SETTINGS",
  COMPARE_SCENARIOS: "COMPARE_SCENARIOS",
  RESET_COMPARE_SCENARIOS: "RESET_COMPARE_SCENARIOS",
  HEADER: "HEADER",
  HEADER_REFRESH_TOGGLE: "HEADER_REFRESH_TOGGLE",
  RESET_HEADER: "RESET_HEADER",
  SET_TAG: "SET_TAG",
  RESET_TAG: "RESET_TAG",
  SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
  // CUSTOMER_LIST
  CUSTOMER_LIST: "CUSTOMER_LIST",
  RESET_CUSTOMER_LIST: "RESET_CUSTOMER_LIST",
  CUSTOMER_LIST_FILTER: "CUSTOMER_LIST_FILTER",
  RESET_CUSTOMER_LIST_FILTER: "RESET_CUSTOMER_LIST_FILTER",
  CUSTOMER_LIST_UPDATE: "CUSTOMER_LIST_UPDATE",
  SET_NEW_CUSTOMER_FILTER: "SET_NEW_CUSTOMER_FILTER",
  RESET_NEW_CUSTOMER_FILTER: "RESET_NEW_CUSTOMER_FILTER",
  // CUSTOMER_DETAILS
  CUSTOMER_DETAILS: "CUSTOMER_DETAILS",
  RESET_CUSTOMER_DETAILS: "RESET_CUSTOMER_DETAILS",
  CUSTOMER_DETAILS_CUSTOMER_REL_DATA: "CUSTOMER_DETAILS_CUSTOMER_REL_DATA",
  RESET_CUSTOMER_DETAILS_CUSTOMER_REL_DATA:
    "RESET_CUSTOMER_DETAILS_CUSTOMER_REL_DATA",
  CUSTOMER_DETAILS_FILTER: "CUSTOMER_DETAILS_FILTER",
  RESET_CUSTOMER_DETAILS_FILTER: "RESET_CUSTOMER_DETAILS_FILTER",
  CUSTOMER_DETAILS_REL_ID: "CUSTOMER_DETAILS_REL_ID",
  CUSTOMER_DETAILS_ID: "CUSTOMER_DETAILS_ID",
  RESET_CUSTOMER_DETAILS_REL_ID: "RESET_CUSTOMER_DETAILS_REL_ID",
  RESET_CUSTOMER_DETAILS_ID: "RESET_CUSTOMER_DETAILS_ID",
  //UX
  INIT_UX: "INIT_UX",
  SET_UX: "SET_UX",
  RESET_UX: "RESET_UX",
  //User
  USER: "USER",
  PAYMENT_ANALYTICS_LIST_FETCHING: "PAYMENT_ANALYTICS_LIST_FETCHING",
  PAYMENT_ANALYTICS_LIST_RESET: "PAYMENT_ANALYTICS_LIST_RESET",
  PAYMENT_ANALYTICS_LIST: "PAYMENT_ANALYTICS_LIST",
  PAYMENT_RECEIPT_FETCH: "PAYMENT_RECEIPT_FETCH",
  PAYMENT_ANALYTICS_LIST_FILTERS: "PAYMENT_ANALYTICS_LIST_FILTERS",
  HELP_PAGE_DETAILS_FETCH: "HELP_PAGE_DETAILS_FETCH",
  SKIP_AR_EMAIL_LIST_FETCH: "SKIP_AR_EMAIL_LIST_FETCH",
  FETCH_CLIENT_DATA: "FETCH_CLIENT_DATA",
  UPDATE_CLIENT_DATA: "UPDATE_CLIENT_DATA",
  PAYROLL_SETTINGS_FETCH: "PAYROLL_SETTINGS_FETCH",
  PAYROLL_SETTINGS_UPDATE: "PAYROLL_SETTINGS_UPDATE",
  //
  SET_PAYABLE_DATA: "SET_PAYABLE_DATA",
  SET_RECEIVABLE_DATA: "SET_RECEIVABLE_DATA",
  FETCH_TASK_LIST: "FETCH_TASK_LIST",
  EDIT_TASK_LIST: "EDIT_TASK_LIST",
  AR_REP_FETCH: "AR_REP_FETCH",
  USER_LIST_FETCH: "USER_LIST_FETCH",
  // Journal Entries
  FETCH_JOURNAL_INFLOW_LIST: "FETCH_JOURNAL_INFLOW_LIST",
  FETCH_JOURNAL_OUTFLOW_LIST: "FETCH_JOURNAL_OUTFLOW_LIST",
  FETCH_JOURNAL_INFLOW_DETAILS: "FETCH_JOURNAL_INFLOW_DETAILS",
  FETCH_JOURNAL_OUTFLOW_DETAILS: "FETCH_JOURNAL_OUTFLOW_DETAILS",
  SET_JOURNAL_INFLOW_FILTERS: "SET_JOURNAL_INFLOW_FILTERS",
  SET_JOURNAL_OUTFLOW_FILTERS: "SET_JOURNAL_OUTFLOW_FILTERS",
  SET_JOURNAL_CATEGORIES: "SET_JOURNAL_CATEGORIES",
  FETCH_JOURNAL_EXPORT: "FETCH_JOURNAL_EXPORT",
  RESET_JOURNAL_FILTERS: "RESET_JOURNAL_FILTERS",
  SET_JOURNAL_COLUMNS: "SET_JOURNAL_COLUMNS",
  // VENDOR_LIST
  VENDOR_LIST: "VENDOR_LIST",
  RESET_VENDOR_LIST: "RESET_VENDOR_LIST",
  SET_VENDOR_FILTER: "SET_VENDOR_FILTER",
  RESET_VENDOR_FILTER: "RESET_VENDOR_FILTER",
  VENDOR_LIST_UPDATE: "VENDOR_LIST_UPDATE",
  // Notes List
  SET_NOTES_LIST: "SET_NOTES_LIST",
  RESET_NOTES_LIST: "RESET_NOTES_LIST",
  SET_NOTES_LIST_FILTER: "SET_NOTES_LIST_FILTER",
  // Sms List
  SET_SMS_LIST: "SET_SMS_LIST",
  RESET_SMS_LIST: "RESET_SMS_LIST",
  SET_SMS_LIST_FILTER: "SET_SMS_LIST_FILTER",
  // Driver Activity
  SET_DRIVER_ACTIVITY_LIST: "SET_DRIVER_ACTIVITY_LIST",
  SET_DRIVER_INVOICE_LIST: "SET_DRIVER_INVOICE_LIST",
  SET_DRIVER_ACTIVITY_FILTER: "SET_DRIVER_ACTIVITY_FILTER",
  SET_DRIVER_INVOICE_FILTER: "SET_DRIVER_INVOICE_FILTER",
  SET_SUPPLIER_REL_DETAILS: "SET_SUPPLIER_REL_DETAILS",
  // Carrier Report
  SET_CARRIER_ACTIVITY_LIST: "SET_CARRIER_ACTIVITY_LIST",
  RESET_CARRIER_ACTIVITY: "RESET_CARRIER_ACTIVITY",
  SET_CARRIER_ACTIVITY_FILTER: "SET_CARRIER_ACTIVITY_FILTER",
  // Invoicing Fields
  SET_INVOICING_FIELDS: "SET_INVOICING_FIELDS",
  // Phone Call
  SET_CALL_DATA: "SET_CALL_DATA",
  RESET_CALL_DATA: "RESET_CALL_DATA",
  INCREMENT_COUNTER: "INCREMENT_COUNTER",
  // Phone Call Reports List
  SET_CALL_LIST: "SET_CALL_LIST",
  RESET_CALL_LIST: "RESET_CALL_LIST",
  SET_CALL_LIST_FILTER: "SET_CALL_LIST_FILTER",
  // Vendor details
  SET_VENDOR_DETAILS_FILTER: "SET_VENDOR_DETAILS_FILTER",
  RESET_VENDOR_DETAILS_FILTER: "RESET_VENDOR_DETAILS_FILTER",
  SET_VENDOR_DETAILS_DATA: "SET_VENDOR_DETAILS_DATA",
  // Receivables list
  SET_RECEIVABLE_LIST_DATA: "SET_RECEIVABLE_LIST_DATA",
  SET_RECEIVABLE_LIST_FILTERS: "SET_RECEIVABLE_LIST_FILTERS",
  RESET_RECEIVABLE_LIST_FILTERS: "RESET_RECEIVABLE_LIST_FILTERS",
  // order backlog list
  SET_ORDER_BACKLOG_LIST_DATA: "SET_ORDER_BACKLOG_LIST_DATA",
  SET_ORDER_BACKLOG_LIST_FILTERS: "SET_ORDER_BACKLOG_LIST_FILTERS",
  RESET_ORDER_BACKLOG_LIST_FILTERS: "RESET_ORDER_BACKLOG_LIST_FILTERS",
  // Payables list
  SET_PAYABLES_LIST_DATA: "SET_PAYABLES_LIST_DATA",
  SET_PAYABLES_LIST_FILTERS: "SET_PAYABLES_LIST_FILTERS",
  RESET_PAYABLES_LIST_FILTERS: "RESET_PAYABLES_LIST_FILTERS",
  // order backlog list
  SET_PURCHASE_ORDER_LIST_DATA: "SET_PURCHASE_ORDER_LIST_DATA",
  SET_PURCHASE_ORDER_LIST_FILTERS: "SET_PURCHASE_ORDER_LIST_FILTERS",
  RESET_PURCHASE_ORDER_LIST_FILTERS: "RESET_PURCHASE_ORDER_LIST_FILTERS",
  // Email report list
  SET_EMAIL_REPORT_LIST_DATA: "SET_EMAIL_REPORT_LIST_DATA",
  SET_EMAIL_REPORT_LIST_FILTERS: "SET_EMAIL_REPORT_LIST_FILTERS",
  RESET_EMAIL_REPORT_LIST_FILTERS: "RESET_EMAIL_REPORT_LIST_FILTERS",
  // Audit Logs
  FETCHING_AUDIT_LOGS: "FETCHING_AUDIT_LOGS",
  SET_AUDIT_LOGS_LIST_SUCCESS: "SET_AUDIT_LIST_SUCCESS",
  SET_AUDIT_LOGS_LIST_FAIL: "SET_AUDIT_LIST_FAIL",
  SET_AUDIT_LOGS_FILTERS: "SET_AUDIT_LOGS_FILTERS",
};
