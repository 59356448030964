import React, { useState } from "react";
import defaultLogo from "assets/images/defaultLogoImage.png";
import { getLocaleString } from "utils/localization/locale";

export default function LogoUpload(props) {
  const { logo, isFetching, getLogoData } = props;
  const [imageError, setImageError] = useState("");
  const [logoData, setLogoData] = useState(logo);

  const onUploadLogo = (e) => {
    const { files } = e.target;
    const file = files[0];
    const size = file.size / 1024;
    var reader = new FileReader();
    if (size > 100) {
      setImageError(
        getLocaleString(
          "settings.settings_company.image_size_should_be_less_than_100kb",
          "Image size should be less than 100kb"
        )
      );
      return;
    }
    setImageError("");
    var canvas = document.getElementById("cv");
    var ctx = canvas.getContext("2d");
    //Read the contents of Image File.
    reader.readAsDataURL(files[0]);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;
      image.onload = function () {
        const height = this.height;
        const width = this.width;
        const canvas = ctx.canvas;
        const hRatio = canvas.width / width;
        const vRatio = canvas.height / height;
        const ratio = Math.min(hRatio, vRatio);
        const centerShift_x = (canvas.width - width * ratio) / 2;
        const centerShift_y = (canvas.height - height * ratio) / 2;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(
          image,
          0,
          0,
          width,
          height,
          centerShift_x,
          centerShift_y,
          width * ratio,
          height * ratio
        );
        setImageError("");
        const logoObj = {
          name: file.name,
          data: canvas.toDataURL("image/png"),
        };
        getLogoData(logoObj);
        setLogoData(logoObj);
      };
    };
  };

  return (
    <div className="form-group mb-3 position-relative d-inline-block profile-pic">
      <input
        type="file"
        id="file"
        className="logo-file-element"
        title={getLocaleString(
          "settings.settings_company.upload_logo",
          "Upload logo"
        )}
        accept="image/png, image/jpeg"
        name="logo"
        onChange={onUploadLogo}
      />
      <label for="file" className="cursor-pointer">
        <div className={`${isFetching ? "circular-loader" : "d-none"}`} />
        <img
          src={logoData.data || defaultLogo}
          alt="Default logo"
          title={getLocaleString(
            "settings.settings_company.click_here_to_upload_logo",
            "Click here to upload logo"
          )}
          className={`${isFetching ? "d-none" : ""}`}
        />
        {logoData.data ? (
          <div className="text-center">
            {getLocaleString(
              "settings.settings_company.click_to_change_logo",
              "Click to change logo"
            )}
          </div>
        ) : (
          <div className="text-center">
            {getLocaleString(
              "settings.settings_company.click_to_upload_logo",
              "Click to upload logo"
            )}
          </div>
        )}
      </label>
      {logoData.data && (
        <div
          title={getLocaleString(
            "settings.settings_company.remove_logo",
            "Remove logo"
          )}
          className="cursor-pointer remove-logo-btn"
          onClick={() => {
            setImageError("");
            const logoObj = {
              name: "remove_logo",
              data: null,
            };
            getLogoData(logoObj);
            setLogoData(logoObj);
          }}
        >
          <i className="fa fa-trash-o cursor-pointer t-red" />
        </div>
      )}
      {imageError && <span className="error t-12 d-block">{imageError}</span>}
      <canvas id="cv" height="80" width="200" className="d-none" />
    </div>
  );
}
