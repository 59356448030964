import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import mapValues from "lodash/mapValues";
import {
  enableOBTabs,
  enableForHardHat,
  enableDefaultAllDateFilter,
} from "handlers/features";
import { getUXSettingsFromStorage } from "utils";
import { getDateByRangeInterval } from "utils/dates";
import { getLocaleString } from "utils/localization/locale";

export const BACKLOG_LIST_COLUMNS = !enableForHardHat()
  ? {
      [getLocaleString("order_backlog.order_id", "Order Id")]: true,
      [getLocaleString("common.customer", "Customer")]: true,
      [getLocaleString("order_backlog.ship_date", "Ship Date")]: true,
      [getLocaleString("order_backlog.order_date", "Order Date")]: true,
      [getLocaleString("common.payIQDate", "PayIQ Date")]: false,
      [getLocaleString("order_backlog.fulfillment_date", "Fulfillment Date")]: false,
      [getLocaleString("order_backlog.department", "Department")]: false,
      [getLocaleString("order_backlog.division", "Division")]: false,
      [getLocaleString("order_backlog.location", "Location")]: false,
      [getLocaleString("common.description", "Description")]: true,
      [getLocaleString("order_backlog.po_number", "PO Number")]: false,
      [getLocaleString("order_backlog.open_amount", "Open Amount")]: true,
      [getLocaleString("order_backlog.unbilled_amount", "Unbilled Amount")]: true,
      [getLocaleString("common.entity", "Entity")]: false,
      [getLocaleString("common.tags", "Tags")]: false,
      [getLocaleString("common.status", "Status")]: true,
    }
  : {
      [getLocaleString("order_backlog.order_id", "Order Id")]: true,
      [getLocaleString("common.customer", "Customer")]: true,
      [getLocaleString("order_backlog.order_date", "Order Date")]: true,
      [getLocaleString("common.totalAmount", "Total Amount")]: true,
      [getLocaleString("common.status", "Status")]: true,
    };

let i = 1;
export const BACKLOG_LIST_COLUMNS_ORDER = mapValues(BACKLOG_LIST_COLUMNS, () => i++);
i = 1;
    
const dateRange = {
  from_date: enableDefaultAllDateFilter()
    ? moment(0).format("MM/DD/YYYY")
    : moment().subtract(365, "days").format("MM/DD/YYYY"),
  to_date: enableDefaultAllDateFilter()
    ? moment().format("MM/DD/YYYY")
    : moment().format("MM/DD/YYYY"),
};

let savedFilters = getUXSettingsFromStorage("OB_FILTERS");
if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const updatedKeys = {};
  const [sd, ed] = getDateByRangeInterval(
    interval === "All" ? "AllToDate" : interval,
    from_date,
    to_date
  );
  savedFilters = {
    ...savedFilters,
    ...updatedKeys,
    from_date: sd,
    to_date: ed,
  };
}

export const initialBacklogFilters = {
  page: 1,
  status: "all",
  tag_ids: [],
  to_date: "",
  hideLines: 0,
  sort_by: "total_amount_open",
  invoiced: 0,
  location: "",
  division: "",
  from_date: "",
  date_type: "order",
  sort_order: "desc",
  not_tag_ids: [],
  department: "",
  selectedTab: enableOBTabs() ? "unbilled" : "unfulfilled",
  invoiceSelect: null,
  customerSelect: null,
  corp_entity_id: "",
  selectedSection: null,
  appliedCustomFilters: {},
  ...dateRange,
};

export const initialBacklogData = {
  isFetching: false,
  isExporting: false,
  listingData: [],
  customFields: [],
  masterFieldValues: {},
  visibleColumns: {
    ...BACKLOG_LIST_COLUMNS,
  },
  exportedData: [],
  filters: {
    ...initialBacklogFilters,
    ...savedFilters,
  },
};

const orderbacklog = (state = initialBacklogData, action) => {
  switch (action.type) {
    case ReducersTypes.SET_ORDER_BACKLOG_LIST_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case ReducersTypes.SET_ORDER_BACKLOG_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case ReducersTypes.RESET_ORDER_BACKLOG_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...initialBacklogData.filters,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default orderbacklog;
