import React, { useState, useCallback, useEffect } from "react";
import { getLocaleString } from "utils/localization/locale";
import ListingTable from "components/ReactTable";
import get from "lodash/get";
import { Link } from "react-router-dom";
import startCase from "lodash/startCase";
import ToggleSwitch from "components/Button/ToggleButton";
import Button from "components/Button";
import { convertAmountWithDecimal } from "utils";
import {
  applyPayment,
  updatePayment,
  getSyncRequest,
  postSyncRequest,
  applyChargeback,
  deleteChargeback,
  getDeductionCode,
  updateSyncRequest,
  getSyncEditableFields,
  getInvoicePaymentDetail,
} from "./actions";
import Toaster from "components/Toaster";

const statusMap = {
  AR_PAYMENT: "Payment",
  CHARGEBACK_INVOICE: "Charge back Invoice",
  CREDIT_MEMO: "Credit Memo",
};

export default function FileProcess(props) {
  const {
    page = 1,
    sort_by = "",
    sort_order = "",
    isFetching = false,
    invoiceList,
    selectedFile,
    setShowProcessWindow,
  } = props;

  const [dedCode, setDedCode] = useState([]);
  const [syncList, setSyncList] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [isFetchingSync, setIsFetchingSyncList] = useState(false);
  const [isFetchingPayments, setIsFetchingPayments] = useState(false);
  const [isSyncingWithERP, setIsSyncingWithERP] = useState(false);
  const [showSyncDetails, setShowSyncDetails] = useState(false);
  const [editableFieldsList, setEditableFieldsList] = useState([]);
  const [isSyncTableUpdated, setIsSyncTableUpdated] = useState(false);
  const [showResponse, setShowResponse] = useState(false);

  const Increment = () => {};

  const Decrement = () => {};

  const handleClick = () => {};

  const getNSSyncList = useCallback((id, params = {}) => {
    setIsFetchingSyncList(true);
    getSyncEditableFields(id).then((response) => {
      if (response) {
        setEditableFieldsList(get(response, "data", {}));
      }
    });
    getSyncRequest(id, { mutate: params.isMutate ? 1 : "" }).then((res) => {
      setShowSyncDetails(true);
      setSyncList(get(res, "data.result", []));
      setIsFetchingSyncList(false);
      setShowResponse(
        get(res, "data.result", []).reduce(
          (acc, item) => (acc = acc || item["response_status"] !== null),
          false
        )
      );
    });
  }, []);

  const getPaymentList = useCallback(() => {
    setIsFetchingPayments(true);
    getInvoicePaymentDetail(get(selectedFile, "id", "")).then((res) => {
      setPaymentDetails(
        get(res, "data.result", []).reduce((acc, cur, indx, array) => {
          if (cur.push_object_type !== "CHARGEBACK_INVOICE") {
            acc.push(
              cur.push_object_type === "CREDIT_MEMO" &&
                array.some((d) => d.linked_line_id === cur.id)
                ? {
                    ...cur,
                    cb_id: get(
                      array.find((d) => d.linked_line_id === cur.id),
                      "id",
                      ""
                    ),
                    create_cb: true,
                  }
                : cur
            );
          }
          return acc;
        }, [])
      );
      setIsFetchingPayments(false);
      document.getElementById("payment_details") &&
        document.getElementById("payment_details").scrollIntoView();
      getNSSyncList(get(selectedFile, "id", ""));
    });
  }, [selectedFile, getNSSyncList]);

  useEffect(() => {
    getDeductionCode().then((res) => {
      setDedCode(res.data.result);
    });
    getPaymentList();
  }, [getPaymentList]);

  const columns = [
    {
      header: () => getLocaleString("common.sr_no", "Sr. No."),
      show: true,
      id: "sr",
      accessorKey: "id",
      className: "ps-3",
      dragable: false,
      size: 50,
      cell: (data) => <div>{get(data, "row.index", 0) + 1}</div>,
    },
    {
      header: () => (
        <div>{getLocaleString("reconcile.invoice_no", "Invoice No")}</div>
      ),
      show: true,
      dragable: false,
      id: "invoice_number",
      accessorKey: "invoice_header.invoice_number",
      size: 200,
      cell: (data) => (
        <>
          <Link
            to={`/receivables/${get(
              data,
              "row.original.invoice_header_id",
              ""
            )}`}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            <span className="t-link">{data.getValue()}</span>
          </Link>
          {!data.getValue() && (
            <span className="t-11">
              {getLocaleString("data_import.Could_not_find_an_open_invoice_parsed_amounts_will_not_be_accurate","Could not find an open invoice, parsed amounts will not be accurate")}
            </span>
          )}
        </>
      ),
    },
    {
      header: () => (
        <div>
          {getLocaleString("cash_remittance.edi_no", "EDI No from file")}
        </div>
      ),
      show: true,
      dragable: false,
      id: "edi_number",
      accessorKey: "invoice_number_detected",
      size: 100,
      cell: (data) => <div>{data.getValue()}</div>,
    },
    {
      header: () => (
        <div>
          {getLocaleString("cash_remittance.invoice_total", "Invoice Total")}
        </div>
      ),
      show: true,
      dragable: false,
      id: "invoice_amount",
      accessorKey: "invoice_header.invoice_amount",
      size: 100,
      cell: (data) => (
        <div>
          {data.getValue() ? convertAmountWithDecimal(data.getValue()) : ""}
        </div>
      ),
    },
    {
      header: () => (
        <div>{getLocaleString("common.amountDue", "Amount Due")}</div>
      ),
      show: true,
      dragable: false,
      id: "total_amount_due",
      accessorKey: "invoice_header.total_amount_due",
      size: 100,
      cell: (data) => (
        <div>
          {data.getValue() ? convertAmountWithDecimal(data.getValue()) : ""}
        </div>
      ),
    },
    {
      header: () => (
        <div>{getLocaleString("common.payment_amount", "Payment Amount")}</div>
      ),
      dragable: false,
      show: false,
      id: "payment_amount",
      accessorKey: "payment_amount",
      size: 150,
      cell: (data) => (
        <div>
          {data.getValue() ? convertAmountWithDecimal(data.getValue()) : ""}
        </div>
      ),
    },
    {
      header: () => <div>{getLocaleString("common.discount", "Discount")}</div>,
      show: true,
      dragable: false,
      id: "discount",
      accessorKey: "discount_amount",
      size: 100,
      cell: (data) => <div>{convertAmountWithDecimal(data.getValue())}</div>,
    },
    {
      header: () => (
        <div>
          {getLocaleString("cash_remittance.change_invoice", "Change Invoice")}
        </div>
      ),
      show: false,
      dragable: false,
      id: "change_invoice",
      accessorKey: "change_invoice",
      size: 150,
      cell: (data) => (
        <div>
          <select className="form-control">
            <option>Select Invoice</option>
            <option>INV111</option>
            <option>INV2321</option>
            <option>INV2121</option>
          </select>
        </div>
      ),
    },
  ];

  const erpPushDataColumns = [
    {
      header: () => getLocaleString("common.sr_no", "Sr. No."),
      show: true,
      accessorKey: "id",
      id: "sr",
      className: "ps-3",
      size: 20,
      dragable: false,
      cell: (data) => <div>{get(data, "row.index", 0) + 1}</div>,
    },
    {
      header: () => <div>{getLocaleString("common.type", "Type")}</div>,
      show: true,
      accessorKey: "push_object_type",
      id: "push_object_type",
      size: 50,
      dragable: false,
      cell: (data) => {
        let invoices = (
          get(data, "row.original.original_json.invoices", []) || []
        ).map((d) => (
          <div className="t-12">
            <strong>{d.edi_invoice_number}</strong> (
            {convertAmountWithDecimal(d.amount_paid)})
          </div>
        ));
        if (invoices.length === 0) {
          invoices = (
            get(data, "row.original.original_json.apply_list", []) || []
          ).map((d) => (
            <div className="t-12">
              <strong>{d.invoice_number}</strong> (
              {convertAmountWithDecimal(d.amount)})
            </div>
          ));
        }
        let expected_unapplied_amount = "";
        if (
          get(data, "row.original.amount") !==
            get(data, "row.original.expected_unapplied_amount") &&
          get(data, "row.original.expected_unapplied_amount", 0) > 0
        ) {
          expected_unapplied_amount = (
            <div className="t-12">
              {getLocaleString("data_import.expected_unapplied_amount","Expected unapplied amount")} <br></br>(
              {convertAmountWithDecimal(
                get(data, "row.original.expected_unapplied_amount")
              )}
              )
            </div>
          );
        }
        return (
          <div>
            {statusMap[data.getValue()]} {invoices} {expected_unapplied_amount}
          </div>
        );
      },
    },
    {
      header: () => <div>{getLocaleString("common.amount", "Amount")}</div>,
      show: true,
      accessorKey: "amount",
      id: "amount",
      size: 50,
      dragable: false,
      cell: (data) => (
        <div>
          <input
            type="number"
            defaultValue={data.getValue()}
            onBlur={(e) => {
              updatePayment(
                get(selectedFile, "id", ""),
                get(data, "row.original.id", ""),
                {
                  amount: e.target.value,
                }
              ).then(() => getPaymentList());
              setSyncList([]);
            }}
            className="form-control"
            placeholder={getLocaleString("common.enter", "Enter...")}
          />
        </div>
      ),
    },
    {
      header: () => (
        <div>
          {getLocaleString("cash_remittance.deduction_code", "Deduction Code")}
        </div>
      ),
      show: true,
      id: "ded_code",
      accessorKey: "deduction_code",
      size: 120,
      dragable: false,
      cell: (data) => {
        const line_data = get(data, "row.original.line_data", {}) || {};
        let line_data_text = Object.keys(line_data).map((keyName, i) => (
          <tr className="t-12">
            <td className="p-1">{keyName}:</td>
            <td className="p-1">{line_data[keyName]}</td>
          </tr>
        ));
        if (get(data, "row.original.push_object_type") === "CREDIT_MEMO") {
          return (
            <div>
              <select
                onChange={(e) => {
                  updatePayment(
                    get(selectedFile, "id", ""),
                    get(data, "row.original.id", ""),
                    {
                      deduction_code: e.target.value,
                    }
                  ).then(() => getPaymentList());
                  setSyncList([]);
                }}
                defaultValue={data.getValue()}
                className="form-control"
              >
                <option value="">
                  {getLocaleString("common.select_code", "Select code")}
                </option>
                {dedCode.map((d) => (
                  <option value={d} key={d}>
                    {d}
                  </option>
                ))}
              </select>
              <table className="border-0 reset-table-style">
                {line_data_text}
              </table>
            </div>
          );
        }
        return "-";
      },
    },
    {
      header: () => (
        <div>
          {getLocaleString(
            "cash_remittance.create_cb",
            "Create Chargeback Invoice"
          )}
        </div>
      ),
      show: true,
      accessorKey: "create_cb",
      id: "create_cb",
      size: 50,
      dragable: false,
      cell: (data) => {
        if (get(data, "row.original.push_object_type") === "CREDIT_MEMO") {
          return (
            <ToggleSwitch
              defaultChecked={data.getValue()}
              id={`paymentToggle-${get(data, "row.original.id", "")}`}
              onChange={(e) => {
                if (e.target.checked) {
                  applyChargeback(
                    get(selectedFile, "id", ""),
                    get(data, "row.original.id", "")
                  ).then(() => getPaymentList());
                } else {
                  deleteChargeback(
                    get(selectedFile, "id", ""),
                    get(data, "row.original.cb_id", "")
                  ).then(() => getPaymentList());
                }
                setSyncList([]);
              }}
            />
          );
        }
        return "-";
      },
    },
  ];

  const erpSyncColumns = [
    {
      header: () => getLocaleString("common.skip_sync", "Sync"),
      show: true,
      accessorKey: "skip_sync",
      className: "ps-3",
      size: 10,
      dragable: false,
      cell: (data) => (
        <div>
          <input
            onChange={(e) => {
              updateSyncRequest(
                get(selectedFile, "id", ""),
                get(data, "row.original.id", ""),
                { skip_sync: !e.target.checked }
              ).then((res) => {
                if (res) {
                  getNSSyncList(get(selectedFile, "id", ""), { isMutate: 1 });
                  setIsSyncTableUpdated(true);
                }
              });
            }}
            defaultChecked={!data.getValue()}
            type="checkbox"
            className="md-checkbox"
          />
        </div>
      ),
    },
    {
      header: () => getLocaleString("common.sr_no", "Sr. No."),
      show: true,
      accessorKey: "id",
      className: "ps-3",
      size: 10,
      dragable: false,
      cell: (data) => <div>{get(data, "row.index", 0) + 1}</div>,
    },
    {
      header: () => <div>{getLocaleString("common.type", "Type")}</div>,
      show: true,
      accessorKey: "push_object_type",
      size: 40,
      dragable: false,
      cell: (data) => <div>{statusMap[data.getValue()]}</div>,
    },
    {
      header: () => <div>{getLocaleString("common.amount", "Amount")}</div>,
      show: true,
      accessorKey: "amount",
      size: 50,
      dragable: false,
      cell: (data) => (
        <div>
          <input
            type="number"
            disabled={
              get(data, "row.original.response_status", "") === "SUCCESS"
            }
            defaultValue={data.getValue()}
            onBlur={(e) => {
              updateSyncRequest(
                get(selectedFile, "id", ""),
                get(data, "row.original.id", ""),
                { amount: e.target.value }
              ).then((res) => {
                if (res) {
                  getNSSyncList(get(selectedFile, "id", ""), { isMutate: 1 });
                }
              });
            }}
            className="form-control"
            placeholder={getLocaleString("common.enter", "Enter...")}
          />
        </div>
      ),
    },
    {
      header: () => (
        <div>
          {getLocaleString("common.original_invoices", "Original Invoices")}
        </div>
      ),
      show: true,
      accessorKey: "request_json.invoices",
      size: 60,
      dragable: false,
      cell: (data) => {
        let invoices = (
          get(data, "row.original.request_json.invoices", []) || []
        ).map((d) => (
          <div className="t-12">
            <strong>{d.edi_invoice_number}</strong> (
            {convertAmountWithDecimal(d.amount_paid)})
          </div>
        ));
        if (invoices.length === 0) {
          invoices = (
            get(data, "row.original.request_json.apply_list", []) || []
          ).map((d) => (
            <div className="t-12">
              <strong>{d.invoice_number}</strong> (
              {convertAmountWithDecimal(d.amount)})
            </div>
          ));
        }
        return (
          <div>
            {statusMap[data.getValue()]} {invoices}
          </div>
        );
      },
    },
    {
      header: () => (
        <div>{getLocaleString("cash_remittance.push_data", "Push Data")}</div>
      ),
      show: true,
      accessorKey: "request_json",
      size: 225,
      dragable: false,
      cell: (data) => (
        <div class="d-flex flex-wrap">
          {Object.keys(data.getValue()).map((d) => {
            const value = data.getValue();
            const isEditable = get(
              editableFieldsList,
              get(data, "row.original.push_object_type", ""),
              []
            ).includes(d);

            if (["apply_list", "invoices", "custom_field_list"].includes(d)) {
              return (
                <div className="m-2 col-4 col-lg-3">
                  <div>
                    <strong>{startCase(d)}</strong>:
                  </div>
                  <textarea
                    rows={8}
                    disabled={
                      isEditable ||
                      get(data, "row.original.response_status", "") ===
                        "SUCCESS"
                    }
                    defaultValue={
                      value[d] ? JSON.stringify(value[d], undefined, 4) : ""
                    }
                    onBlur={(e) => {
                      if (
                        JSON.stringify(value[d], undefined, 4) !==
                        e.target.value
                      ) {
                        updateSyncRequest(
                          get(selectedFile, "id", ""),
                          get(data, "row.original.id", ""),
                          {
                            request_json: {
                              ...value,
                              ...{ [d]: JSON.parse(e.target.value) },
                            },
                          }
                        ).then((res) => {
                          if (res) {
                            Toaster(
                              getLocaleString(
                                "settings_data.erp_sync_line_updated",
                                "ERP sync line updated"
                              ),
                              "success"
                            );
                            getNSSyncList(get(selectedFile, "id", ""), {
                              isMutate: 1,
                            });
                            setIsSyncTableUpdated(true);
                          }
                        });
                      }
                    }}
                    className="form-control"
                  />
                </div>
              );
            }
            return (
              <div className="m-2 col-4 col-lg-3">
                <div>
                  <strong>{startCase(d)}</strong>:{" "}
                  <input
                    type="text"
                    disabled={
                      isEditable ||
                      get(data, "row.original.response_status", "") ===
                        "SUCCESS"
                    }
                    onBlur={(e) => {
                      if ((value[d] || "").toString() !== e.target.value) {
                        updateSyncRequest(
                          get(selectedFile, "id", ""),
                          get(data, "row.original.id", ""),
                          {
                            request_json: {
                              ...value,
                              [d]: e.target.value,
                            },
                          }
                        ).then((res) => {
                          if (res) {
                            Toaster(
                              getLocaleString(
                                "settings_data.erp_sync_line_updated",
                                "ERP sync line updated"
                              ),
                              "success"
                            );
                            getNSSyncList(get(selectedFile, "id", ""), {
                              isMutate: 1,
                            });
                            setIsSyncTableUpdated(true);
                          }
                        });
                      }
                    }}
                    className="form-control"
                    defaultValue={value[d]}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      header: () => <div>{getLocaleString("common.response", "Response")}</div>,
      show: showResponse,
      accessorKey: "erp_url",
      size: 50,
      dragable: false,
      cell: (data) => {
        if (data.getValue()) {
          return (
            <Link
              to={{ pathname:data.getValue()}}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
            >
              <span className="t-link">
                {getLocaleString("common.erp_link", "Netsuite object link", {
                  erp: "Netsuite",
                })}
              </span>
            </Link>
          )
        }
      }
    },
    {
      header: () => (
        <div>{getLocaleString("cash_remittance.check_no", "Check No")}</div>
      ),
      show: false,
      accessorKey: "check_no",
      id: "check_no",
      size: 100,
      dragable: false,
      cell: (data) => (
        <div>
          <input
            disabled={
              get(data, "row.original.response_status", "") === "SUCCESS"
            }
            type="text"
            defaultValue={data.getValue()}
            className="form-control"
            placeholder={getLocaleString("common.enter", "Enter...")}
          />
        </div>
      ),
    },
    {
      header: () => (
        <div>{getLocaleString("common.paymentMethod", "Payment Method")}</div>
      ),
      show: false,
      accessorKey: "payment_method",
      id: "payment_method",
      size: 100,
      dragable: false,
      cell: (data) => (
        <div>
          <input
            type="text"
            disabled={
              get(data, "row.original.response_status", "") === "SUCCESS"
            }
            defaultValue={data.getValue()}
            className="form-control"
            placeholder={getLocaleString("common.enter", "Enter...")}
          />
        </div>
      ),
    },
    {
      header: () => <div>{getLocaleString("common.customer", "Customer")}</div>,
      show: false,
      accessorKey: "customer",
      id: "customer",
      size: 150,
      dragable: false,
      cell: (data) => <div>{data.getValue()}</div>,
    },
    {
      header: () => (
        <div>
          {getLocaleString("cash_remittance.deduction_code", "Deduction Code")}
        </div>
      ),
      show: false,
      id: "ded_code",
      dragable: false,
      accessorKey: "request_json.deduction_code",
      size: 150,
      cell: (data) => {
        if (get(data, "row.original.push_object_type") === "CREDIT_MEMO") {
          return (
            <div>
              <select
                disabled={
                  get(data, "row.original.response_status", "") === "SUCCESS"
                }
                onChange={(e) => {
                  updateSyncRequest(
                    get(selectedFile, "id", ""),
                    get(data, "row.original.id", ""),
                    { deduction_code: e.target.value }
                  ).then((res) => {
                    if (res) {
                      getNSSyncList(get(selectedFile, "id", ""), {
                        isMutate: 1,
                      });
                    }
                  });
                }}
                defaultValue={data.getValue()}
                className="form-control"
              >
                <option>
                  {getLocaleString("common.select_code", "Select code")}
                </option>
                {dedCode.map((d) => (
                  <option value={d} key={d}>
                    {d}
                  </option>
                ))}
              </select>
            </div>
          );
        }
        return "-";
      },
    },
    {
      header: () => (
        <div>{getLocaleString("order_backlog.po_number", "PO Number")}</div>
      ),
      show: false,
      accessorKey: "customer",
      id: "po_number",
      size: 150,
      dragable: false,
      cell: (data) => (
        <div>
          <input
            disabled={
              get(data, "row.original.response_status", "") === "SUCCESS"
            }
            type="text"
            defaultValue={data.getValue()}
            className="form-control"
            placeholder={getLocaleString("common.enter", "Enter...")}
          />
        </div>
      ),
    },
  ];

  const renderTotalBlock = () => {
    const totalAmount = paymentDetails.reduce((acc, cur) => {
      if (cur.push_object_type === "AR_PAYMENT") {
        acc += cur.amount;
      }
      return acc;
    }, 0);

    const discountAmount = invoiceList.reduce(
      (acc, cur) => (acc += get(cur, "discount_amount", 0)),
      0
    );

    const deductionAmount = paymentDetails.reduce((acc, cur) => {
      if (cur.push_object_type === "CREDIT_MEMO") {
        acc += cur.amount;
      }
      return acc;
    }, 0);

    return (
      <div className="border my-3 t-13">
        <div className="p-1 px-2">
          <strong>{getLocaleString("common.totals", "Totals")}</strong>
        </div>
        <div className="border-top p-2">
          <p>
            <strong>
              {getLocaleString(
                "settings_data.total_amount_paid",
                "Total Amount Paid"
              )}
              :
            </strong>{" "}
            {convertAmountWithDecimal(totalAmount)}
            <br />
          </p>
          <p>
            <strong>
              {getLocaleString(
                "settings_data.total_discount_amount",
                "Total Discount Amount"
              )}
              :
            </strong>{" "}
            {convertAmountWithDecimal(discountAmount)}
          </p>
          <p>
            <strong>
              {getLocaleString(
                "settings_data.total_deduction_amount",
                "Total Deduction Amount"
              )}
              :
            </strong>{" "}
            {convertAmountWithDecimal(deductionAmount)}
            <br />
            {totalAmount === 0 && discountAmount > 0 && (
              <span className="t-11">
              {getLocaleString("data_import.deduction_amount_is_less_as_it_compensates_for_the_discounts_applied","*Deduction amount is less as it compensates for the discounts applied")}
              </span>
            )}
          </p>
        </div>
      </div>
    );
  };

  const disableSync = syncList.every(
    (d) => get(d, "response_status", "") === "SUCCESS"
  );

  return (
    <div className="row">
      <div className="col-md-12 mb-2">
        <h6>
          <span
            onClick={() => {
              setSyncList([]);
              setPaymentDetails([]);
              setShowSyncDetails(false);
              setShowProcessWindow(false);
              setIsSyncTableUpdated(false);
            }}
            title={getLocaleString("dashboard.back", "Back")}
          >
            <i
              className="fa fa-arrow-left"
              data-tlabel="left-arrow"
              style={{
                marginRight: "15px",
                ariaHidden: true,
                cursor: "pointer",
              }}
            />
          </span>
          {get(selectedFile, "original_name", "")} (
          {get(selectedFile, "customer_detected", "")})
        </h6>
      </div>
      <div className="col-md-12 mt-2">
        <ListingTable
          minRows={invoiceList.length > 20 ? 1 : invoiceList.length}
          isFetching={isFetching}
          columns={columns}
          data={invoiceList}
          currPage={page}
          hidePagination
          defaultSorted={
            sort_by
              ? [
                  {
                    id: sort_by,
                    desc: sort_order === "desc",
                  },
                ]
              : []
          }
          nextPage={invoiceList.length >= 20 ? page + 1 : null}
          prevPage={page !== 1 ? page - 1 : null}
          setNextPage={Increment}
          setPrevPage={Decrement}
          noDataText={
            isFetching
              ? ""
              : getLocaleString("common.nodataFound", "No data found")
          }
        />
      </div>
      <div id="payment_details" className="col-md-10 mt-4">
        <ListingTable
          minRows={paymentDetails.length > 20 ? 1 : paymentDetails.length}
          isFetching={isFetchingPayments}
          columns={erpPushDataColumns}
          data={paymentDetails}
          currPage={page}
          hidePagination
          onRowClick={(rowInfo) => handleClick(rowInfo)}
          sorting={
            sort_by
              ? [
                  {
                    id: sort_by,
                    desc: sort_order === "desc",
                  },
                ]
              : []
          }
          nextPage={paymentDetails.length >= 20 ? page + 1 : null}
          prevPage={page !== 1 ? page - 1 : null}
          setNextPage={Increment}
          setPrevPage={Decrement}
          noDataText={
            isFetchingPayments
              ? ""
              : getLocaleString("common.nodataFound", "No data found")
          }
        />
        <div className="float-start">
          {getLocaleString(
            "settings_data.you_can_use_apply_to_reset",
            "*You can also use the apply button to reset the ERP sync data values in the table below"
          )}
        </div>
        <div className="float-end mt-2">
          {(!disableSync || !syncList.length) && (
            <Button
              disabled={isFetchingSync || isFetchingPayments}
              onClick={() =>
                applyPayment(get(selectedFile, "id", "")).then((res) => {
                  if (res) {
                    Toaster(
                      getLocaleString(
                        "settings_data.payment_applied",
                        "Payment applied"
                      ),
                      "success"
                    );
                    getNSSyncList(get(selectedFile, "id", ""));
                    document.getElementById("sync_details") &&
                      document.getElementById("sync_details").scrollIntoView();
                  }
                })
              }
            >
              {getLocaleString("common.apply", "Apply")}
              {isFetchingSync || isFetchingPayments ? (
                <i className="ms-1 fa fa-spinner fa-spin" />
              ) : null}
            </Button>
          )}
        </div>
      </div>
      <div className="col-md-2 mt-3">{renderTotalBlock()}</div>
      {showSyncDetails ? (
        <div id="sync_details" className="col-md-12 mt-5">
          <h6>
            {getLocaleString("common.push_to_erp", "Push to ERP", {
              erp: "Netsuite",
            })}
          </h6>
          <ListingTable
            minRows={syncList.length > 20 ? 1 : syncList.length}
            isFetching={isFetchingSync}
            columns={erpSyncColumns}
            data={syncList}
            currPage={page}
            hidePagination
            sorting={
              sort_by
                ? [
                    {
                      id: sort_by,
                      desc: sort_order === "desc",
                    },
                  ]
                : []
            }
            nextPage={syncList.length >= 20 ? page + 1 : null}
            prevPage={page !== 1 ? page - 1 : null}
            setNextPage={Increment}
            setPrevPage={Decrement}
            noDataText={
              isFetchingSync
                ? ""
                : getLocaleString("common.nodataFound", "No data found")
            }
          />
          {!disableSync ? (
            <div className="text-end py-3 bg-white position-sticky bottom-0">
              {isSyncTableUpdated ? (
                <div className="mt-2 text-start float-start">
                  {getLocaleString(
                    "settings_data.erp_sync_line_updated",
                    "ERP sync line updated"
                  )}
                </div>
              ) : null}
              <Button
                disabled={isSyncingWithERP}
                className="pulse"
                onClick={() => {
                  setIsSyncingWithERP(true);
                  postSyncRequest(
                    get(selectedFile, "id", ""),
                    get(syncList, "[0].remittance_file_erp_sync_id", "")
                  ).then((res) => {
                    if (res) {
                      setIsSyncTableUpdated(false);
                      getNSSyncList(get(selectedFile, "id", ""), {
                        isMutate: 1,
                      });
                      Toaster(
                        getLocaleString(
                          "settings_data.netsuite_push_completed",
                          "Netsuite push completed"
                        ),
                        "success"
                      );
                    }
                  });
                }}
              >
                {getLocaleString("common.sync", "Sync")}
                {isSyncingWithERP ? (
                  <i className="ms-1 fa fa-spinner fa-spin" />
                ) : null}
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
