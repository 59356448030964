import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import { enableDefaultAllDateFilter } from "handlers/features";
import { getUXSettingsFromStorage } from "utils";
import { getDateByRangeInterval } from "utils/dates";

export const REPORTS_PHONE_COLUMNS_ORDER = {
  Customer: 1,
  "Called On": 2,
  Caller: 3,
  Receiver: 4,
  Duration: 5,
  Status: 6,
  Recording: 7,
};

const dateRange = {
  from_date: enableDefaultAllDateFilter()
    ? moment(0).format("MM/DD/YYYY")
    : moment().subtract(365, "days").format("MM/DD/YYYY"),
  to_date: enableDefaultAllDateFilter()
    ? moment().add(1, "year").format("MM/DD/YYYY")
    : moment().format("MM/DD/YYYY"),
};

let savedFilters = getUXSettingsFromStorage("ANALYTICS_CALL_FILTERS");
if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const updatedKeys = {};
  const [sd, ed] = getDateByRangeInterval(
    interval === "All" ? "AllToDate" : interval,
    from_date,
    to_date
  );
  savedFilters = {
    ...savedFilters,
    ...updatedKeys,
    from_date: sd,
    to_date: ed,
  };
}

export const initialCallListFilters = {
  page: 1,
  sort_by: "sent_at",
  sort_order: "desc",
  ...dateRange,
  client_customer_rel_id: "",
  status: ""
};

const initialState = {
  listData: [],
  receiversList: [],
  isFetching: false,
  filters: {
    ...initialCallListFilters,
    ...savedFilters,
    ...dateRange,
  },
  exportedData: [],
  showexport: false,
  exporting: false,
};

const reportsCallList = (state = initialState, { type, payload }) => {
  switch (type) {
    case ReducersTypes.SET_CALL_LIST:
      return {
        ...state,
        ...payload.data,
      };
    case ReducersTypes.SET_CALL_LIST_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload.data,
        },
      };
    case ReducersTypes.RESET_CALL_LIST:
      return {
        ...initialState,
        receiversList: state.receiversList,
        filters: {
          ...initialState.filters,
        },
      };
    default:
      return state;
  }
};

export default reportsCallList;
