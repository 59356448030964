/* eslint-disable react-hooks/exhaustive-deps */
import get from "lodash/get";
import React, { useEffect, useState, useRef } from "react";
import { getFormattedDate } from "utils/dates";
import jsPDF from "jspdf";
import groupBy from "lodash/groupBy";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { getCreditApplicationReports, searchCustomer } from "./actions";
import { getCreditApplicationDetails } from "containers/customers/CreditApplication/actions";
import DateRangePicker from "components/DateRangePicker";
import Button from "components/Button";
import debounce from "lodash/debounce";
import moment from "moment";
import Autocomplete from "components/Autocomplete";
import { setuxvalue, getuxvalue } from "handlers/ux";
import omit from "lodash/omit";
import ListingTable from "components/ReactTable";
import DropdownButton from "components/Button/Dropdown";
import { getLocaleString } from "utils/localization/locale";

export const initialCreditApplicationFilters = {
  from_date: moment(0).format("MM/DD/YYYY"),
  to_date: moment().format("MM/DD/YYYY"),
  client_customer_rel_id: "",
  interval: "all",
  page: 1,
};

function CreditApplication({ client_customer_rel_id = "" }) {
  const [rows, setRows] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    ...initialCreditApplicationFilters,
    ...getuxvalue("ANALYTICS_CREDIT_APPLICATION"),
  });
  const [isCustomerFetching, setIsCustomerFetching] = useState(false);
  const [customerList, setCustomerList] = useState([]);

  const { page = 1 } = filters;

  const debounceMethod = useRef(
    debounce(
      (text) =>
        searchCustomer(text, (list) => {
          setCustomerList(list);
          setIsCustomerFetching(false);
        }),
      300
    )
  ).current;

  const [pagination, setPagination] = useState({
    pages: 1,
    next_num: null,
    prev_num: null,
  });

  const getData = async (appliedFilters = filters) => {
    setIsFetching(true);
    const filter = omit(
      { ...filters, ...appliedFilters },
      !client_customer_rel_id
        ? ["interval", "customerSelect"]
        : ["interval", "customerSelect", "from_date", "to_date"]
    );
    if (client_customer_rel_id) {
      filter.client_customer_rel_id = client_customer_rel_id;
    }
    setFilters(filter);
    const reportsData = await getCreditApplicationReports({ ...filter });
    setIsFetching(false);
    setRows(get(reportsData, "result", []));
    setuxvalue("ANALYTICS_CREDIT_APPLICATION", appliedFilters);
    setPagination({
      pages: get(reportsData, "pages", 1),
      next_num: get(reportsData, "next_num", 0),
      prev_num: get(reportsData, "prev_num", 0),
    });
  };

  const onReset = () => {
    setShowFilter(false);
    setFilters({ ...initialCreditApplicationFilters });
    getData(initialCreditApplicationFilters);
  };

  const setNextPage = () => getData({ page: page + 1 });

  const setPage = (page) => getData({ page });

  const setPrevPage = () => getData({ page: page - 1 });

  const getApplicationData = async (
    customer_id = client_customer_rel_id,
    customerName = "",
    downloadPdf = false
  ) => {
    const applicationData = await getCreditApplicationDetails({
      client_customer_rel_id: customer_id,
    });
    let fieldsSeparatedByTabs = groupBy(
      get(applicationData, "result", []),
      "description"
    );
    fieldsSeparatedByTabs = Object.keys(fieldsSeparatedByTabs).map(
      (sectionName) => ({
        sectionName,
        sectionFields: fieldsSeparatedByTabs[sectionName],
      })
    );
    if (downloadPdf) {
      generatePDF(fieldsSeparatedByTabs, customerName);
    }
  };

  useEffect(() => {
    getData();
    if (client_customer_rel_id) {
      getApplicationData(client_customer_rel_id);
    }
  }, []);

  const generatePDF = (application, customerName) => {
    const doc = new jsPDF();
    application.forEach((section) => {
      let info = [];
      let header = [];
      if (section.sectionFields.length) {
        header = section.sectionFields.map((d) => d.name);
        info = section.sectionFields.map((d) => d.value);
      }

      let finalY = get(doc, "lastAutoTable.finalY", 0);
      doc.text(10, finalY + 12, section.sectionName);

      try {
        const cellWidth =
          (doc.internal.pageSize.width - 20) / (header.length || 1);
        doc.autoTable({
          head: [header],
          body: section.sectionFields.length.length ? info : [info],
          startY: finalY + 15,
          theme: "grid",
          styles: { fontSize: 8, halign: "center" },
          rowPageBreak: "avoid",
          margin: { horizontal: 10 },
          headStyles: {
            valign: "middle",
            cellWidth,
            halign: "left",
            fillColor: "#bdc0bf",
            textColor: "black",
          },
        });
      } catch (e) {}
    });
    const fileName = `${customerName}_credit_application.pdf`;
    doc.save(fileName);
  };

  const columns = [
    {
      header: () => (
        <span>
          {getLocaleString("order_backlog.customer_name", "Customer Name")}
        </span>
      ),
      size: 200,
      show: true,
      dragable: false,
      accessorKey: "name",
      id: "name",
      cell: (data) => {
        return (
          <span className="px-3">
            {client_customer_rel_id ? (
              <span>{data.getValue()}</span>
            ) : (
              <Link
                to={`/customers/rel/${get(
                  data,
                  "row.original.client_customer_rel_id",
                  ""
                )}#creditapplication`}
                target="_blank"
              >
                <span className="text-link">{data.getValue()}</span>
              </Link>
            )}
          </span>
        );
      },
    },
    {
      header: () => (
        <span>
          {getLocaleString("creditapplication.applied_date", "Applied Date")}
        </span>
      ),
      size: 200,
      dragable: false,
      show: true,
      accessorKey: "date",
      id: "date",
      className: "text-end",
      headerClassName: "text-end",
      cell: (data) => {
        return (
          <span className="">
            {data.getValue()
              ? getFormattedDate(new Date(data.getValue()))
              : "-"}
          </span>
        );
      },
    },
    {
      header: () => <span />,
      size: 200,
      show: true,
      className: "text-end mt-n2 pe-3",
      accessorKey: "id",
      id: "id",
      dragable: false,
      cell: (data) => {
        return (
          <span
            title="Download Application"
            onClick={() => {
              getApplicationData(
                get(data, "row.original.client_customer_rel_id", ""),
                get(data, "row.original.name", ""),
                true
              );
            }}
          >
            <i
              data-tlabel={`Download application for ${get(
                data,
                "row.original.name",
                ""
              )}`}
              className="fa fa-file-pdf-o color-blue t-20"
            />
          </span>
        );
      },
    },
  ];

  return (
    <div className="card-body">
      <div>
        <div className="row mb-4">
          {!client_customer_rel_id ? (
            <DropdownButton
              showDropdown={showFilter}
              buttonProps={{
                as: "span",
                role: "button",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false",
              }}
              btnClassName="dropdown-toggle cursor-pointer"
              setShowDropdown={setShowFilter}
              buttonText={getLocaleString("common.filter", "Filter")}
              dropdownWrapperClassName="filterdroplist p-2 width-500px"
              dropdownWrapperProps={{
                id: "outerside",
              }}
              filterButton
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-0">
                    <label>
                      {getLocaleString(
                        "creditapplication.applied_date",
                        "Applied Date"
                      )}
                    </label>
                    <div className="single-date-picker t-12 overflow-hidden">
                      <DateRangePicker
                        all={true}
                        label={get(filters, "interval", "")}
                        startDate={moment(get(filters, "from_date", ""))}
                        endDate={moment(get(filters, "to_date", ""))}
                        getSelectedDates={(from_date, to_date, interval) => {
                          setFilters({
                            ...filters,
                            from_date: from_date.format("MM/DD/YYYY"),
                            to_date: to_date.format("MM/DD/YYYY"),
                            interval,
                          });
                        }}
                        parentEl="#outerside"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <div className="pagepayable">
                      <label className="">
                        {getLocaleString("common.customer", "Customer")}
                      </label>
                      <Autocomplete
                        suggestions={customerList}
                        getSelected={(item) => {
                          setFilters({
                            ...filters,
                            customerSelect: item,
                            client_customer_rel_id: item ? item.id : "",
                          });
                        }}
                        search={(text) => {
                          setIsCustomerFetching(true);
                          debounceMethod(text);
                        }}
                        isLoading={isCustomerFetching}
                        defaultValue={get(filters, "customerSelect.name", "")}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" />
                <div className="col-md-6">
                  <div className="setbtn mt-1">
                    <Button
                      type="button"
                      variant="light"
                      data-testid="resetbtn"
                      className="resetbtn"
                      onClick={onReset}
                    >
                      {getLocaleString("common.reset", "Reset")}
                    </Button>
                    <Button
                      type="button"
                      data-testid="applybtn"
                      className="applybtn"
                      onClick={() => {
                        getData();
                        setShowFilter(false);
                      }}
                    >
                      {getLocaleString("common.submit", "Submit")}
                    </Button>
                  </div>
                </div>
              </div>
            </DropdownButton>
          ) : null}
        </div>
        <div className="row p-0">
          <div className="col-md-12 p-0">
            <ListingTable
              data={rows}
              columns={columns}
              isFetching={isFetching}
              sorting={[]}
              showPaginationSwitch
              currPage={page}
              setPage={setPage}
              totalPages={pagination.pages}
              nextPage={get(pagination, "next_num", "")}
              prevPage={get(pagination, "prev_num", "")}
              setNextPage={setNextPage}
              setPrevPage={setPrevPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditApplication;
