import axios from "axios";
import isArray from "lodash/isArray";
import keys from "lodash/keys";
import get from "lodash/get";
import { getTimeZoneDetails } from "utils/dates";
import TokenManager from "utils/TokenManager";
import moment from "moment";
import { default as Configs } from "../constants/Configs";
const https = window.cy ? {} : require("https");

const BASE_URL = Configs.API_URL;
const GLOBAL_TOKEN =
  "eyJhbGciOiJIUzUxMiIsImlhdCI6MTU3MzkwMjI0MCwiZXhwIjoxNTczOTAyODQwfQ.eyJpZCI6MX0.OHHQTsLuK7Cy9dsklnp-JumDoJVdBrCUh6j21gr0YWgz3uLkhpmamAKS6vntIh9MTraXcYlItqA_NUFcc4nXMw";

// At request level
export const agent = window.cy ? {} :  new https.Agent({
  rejectUnauthorized: false,
});

const clientCurrency = TokenManager.getClientCurrencyCode();
const corpEntityCurrency = TokenManager.getCurrencyCode();
const isEntitySelected = TokenManager.isCorpEntityRelatedToUser();
export const isDifferentCurrency =
  isEntitySelected &&
  corpEntityCurrency &&
  clientCurrency !== corpEntityCurrency;

export let cancelAPI = axios.CancelToken.source();
export const regenerateCancelToken = () => {
  cancelAPI.cancel();
  cancelAPI = axios.CancelToken.source();
};

const MAX_RETRY = 2;
const RETRY_INTERVAL = 5000;

const transformURL = (url) =>
  url
    .split("&")
    .filter((x) => x)
    .map((x) => x.split("="))
    .map((param) =>
      ["from_date", "to_date"].includes(param[0]) ? [param[0], param[1]] : param
    )
    .map((param) => {
      if (
        ["corp_entity_id", "corporate_entity_id"].includes(param[0]) &&
        (param[1] == null || param[1] === "null")
      ) {
        return null;
      } else {
        return param;
      }
    })
    .filter((param) => param)
    .map((x) => x.join("="))
    .join("&");

export const setAuthorizationToken = () => {
  return TokenManager.get() || GLOBAL_TOKEN;
};

const ConvertHeadersForBackend = (headers) => {
  headers.Authorization = setAuthorizationToken();
  headers["todays_date"] = moment().format("MM/DD/YYYY");
  if (get(getTimeZoneDetails(), "name", "")) {
    headers["timezone"] = get(getTimeZoneDetails(), "name", "");
  }
  if (get(getTimeZoneDetails(), "offset", "")) {
    headers["time_zone"] = get(getTimeZoneDetails(), "offset", "");
  }
  headers["Content-Type"] = "application/json";
  // headers["Access-Control-Allow-Origin"] = "*";
  if (isDifferentCurrency) {
    headers["use_foreign_keys"] = 1;
  }
  return headers;
};

const urlCheck = (url) => {
  if (url.includes("?")) {
    const prev = url.substr(0, url.indexOf("?") + 1);
    const url_to_transform = url.substr(url.indexOf("?") + 1, url.length);
    const aft = transformURL(url_to_transform);
    return prev + aft;
  }
  return url;
};

const filterArrayValue = (arr) =>
  arr.map((d) => {
    if (typeof d == "object" && !isArray(d)) {
      (d["corp_entity_id"] == null || d["corp_entity_id"] === "null") &&
        delete d["corp_entity_id"];
      (d["corporate_entity_id"] == null ||
        d["corporate_entity_id"] === "null") &&
        delete d["corporate_entity_id"];
    }
    return d;
  });

const dataCheck = (data, isrecursive) => {
  if (!data) {
    return data;
  }
  let result;
  if (isArray(data)) {
    result = filterArrayValue(data);
  } else if (!isArray(data) && typeof data == "object") {
    result = {};
    keys(data).forEach((key) => {
      if (key === "corp_entity_id") {
        if (
          data["corp_entity_id"] != null &&
          data["corp_entity_id"] !== "null"
        ) {
          result[key] = dataCheck(data[key], true);
        }
      } else if (key === "corporate_entity_id") {
        if (
          data["corporate_entity_id"] != null &&
          data["corporate_entity_id"] !== "null"
        ) {
          result[key] = dataCheck(data[key], true);
        }
      } else {
        result[key] = dataCheck(data[key], true);
      }
    });
  }
  result =
    data && typeof data === "object" && !isrecursive
      ? JSON.stringify(data)
      : data;
  return result;
};

export const instance = axios.create({
  baseURL: BASE_URL,
  transformRequest: [
    (data, headers) => {
      headers = ConvertHeadersForBackend(headers);
      return dataCheck(data);
    },
  ],
});

// declare a request interceptor
instance.interceptors.request.use(
  (config) => {
    const { url } = config;
    config.url = urlCheck(url);
    if (!get(config, "auth", "")) {
      config.auth = {
        username: TokenManager.get(),
        password: Math.random().toString(20),
      };
    }
    config.cancelToken = cancelAPI.token;
    return config;
  },
  (error) => {
    // handle the error
    console.log("___error in axios req", JSON.stringify(error));
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      console.log("___error in axios resp", JSON.stringify(error.response));
      return Promise.reject({
        ...error.response.data,
        status: error.response.status,
      });
    }
    console.log("___error in axios resp", JSON.stringify(error));
    return Promise.reject(error);
  }
);

export const instanceWithRetry = axios.create({
  baseURL: BASE_URL,
  transformRequest: [
    (data, headers) => {
      headers = ConvertHeadersForBackend(headers);
      const res = dataCheck(data);
      return res;
    },
  ],
});

// declare a request interceptor
instanceWithRetry.interceptors.request.use(
  (config) => {
    const { url } = config;
    config.url = urlCheck(url);
    if (!get(config, "auth", "")) {
      config.auth = {
        username: TokenManager.get(),
        password: Math.random().toString(20),
      };
    }
    config.cancelToken = cancelAPI.token;
    return config;
  },
  (error) => {
    // handle the error
    console.log("___error in axios req retry", JSON.stringify(error));
    return Promise.reject(error);
  }
);

let API_IN_PROGRESS = "";
const retryRequest = (originalRequest) => {
  console.log("retryRequest: ", originalRequest);
  if (API_IN_PROGRESS === originalRequest.url) {
    return null;
  }
  API_IN_PROGRESS = originalRequest.url;
  return new Promise((resolve, _) => {
    const updatedOriginalRequest = originalRequest.data
      ? {
          ...originalRequest,
          data: JSON.parse(get(originalRequest, "data", "{}")),
        }
      : originalRequest;
    setTimeout(() => {
      resolve(instanceWithRetry(updatedOriginalRequest));
    }, RETRY_INTERVAL);
  });
};

instanceWithRetry.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { config } = error;
    const originalRequest = config;
    try {
      console.log("instanceWithRetry request", JSON.stringify(originalRequest));
    } catch (e) {
      console.log("instanceWithRetry err", e);
    }
    if (!get(error, "response.status", "") && originalRequest && (originalRequest.retry || 1) < MAX_RETRY) {
      return retryRequest({...originalRequest, retry: originalRequest.retry ? originalRequest.retry + 1 : 1});
    } else {
      if (error.response) {
        console.log("___error in axios resp", JSON.stringify(error.response));
        return Promise.reject({
          ...error.response.data,
          status: error.response.status,
        });
      }
      console.log("___error in axios resp", JSON.stringify(error));
      return Promise.reject(error);
    }
  }
);

export const instanceMultipart = axios.create({
  baseURL: BASE_URL,
  httpsAgent: agent,
  transformRequest: [
    (data, headers) => {
      ConvertHeadersForBackend(headers);
      headers["Content-Type"] = "multipart/form-data";
      headers["httpsAgent"] = agent;
      return data;
    },
  ],
});

export const downloadFile = (url, name) => {
  let urlExt = url.split("/");
  urlExt = urlExt[urlExt.length - 1];
  instance
    .post("url", { url }, { responseType: "blob" })
    .then((result) => {
      const newurl = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = newurl;
      link.setAttribute("download", name || urlExt);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
    });
};

// declare a request interceptor
instanceMultipart.interceptors.request.use(
  (config) => {
    config.url = transformURL(config.url);
    if (!get(config, "auth", "")) {
      config.auth = {
        username: TokenManager.get(),
        password: Math.random().toString(20),
      };
    }
    config["httpsAgent"] = agent;
    return config;
  },
  (error) => {
    // handle the error
    return Promise.reject(error);
  }
);
